.modal-overlay-lock {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-lock {
    background-color: #FFF;
    padding: 60px;
    border-radius: 8px;
    text-align: center;
    h2{
        font-size: 20px;
    }
}

.modal-lock button {
    margin: 30px 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    background-color: #1F315C;
    color: #FFF;
    width: 250px;
    height: 45px;
}
.no-button-lock{
    border: 1px solid #1F315C !important;
    color: #000 !important;
    background-color: #FFF !important;
}
