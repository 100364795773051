.signInButton {
    background-color: #CCC;
    color: #FFF;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:disabled {
        cursor: not-allowed;
    }

    &.filled {
        background-color: #1F315C;

        &:hover {
            background-color: #1F315C;
        }
    }

    &.empty {
        background-color: lightgray;

        &:hover {
            background-color: gray;
        }
    }
}

.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.error {
    color: red;
    margin-bottom: 10px;
}

.signin-container {
    max-width: 600px;
    .form-group {
        margin-bottom: 30px;

        label {
            display: block;
            margin-bottom: 5px;
        }

        input {
            width: 400px;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
        }
    }

    .forgot-password {
        margin-bottom: 45px;
        a {
            color: blue;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}