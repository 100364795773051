@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body{
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 1440px) and (min-height: 1024px) {}
.Una-home {
    display: block;
    align-items: center;
}
.select-section{
    margin-top: 20px !important;
}
.select-box-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
}

.cams-wrapper{
    display: flex;
    gap: 40px;
    width: 80vw;
}

.Browser-button {
    width: 200px;
    border:none;
    height: 45px;
    background:#1F315C;
    padding: 10px 20px;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    margin-left: 0px;
    cursor: pointer;
    margin-top: 50px;
    text-align: center;
}

.Browser-button-sync {
  width: 200px;
  border:none;
  height: 45px;
  background:#1F315C;
  padding: 10px 20px;
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  margin-left: 0px;
  cursor: pointer;
  margin-top: 50px;
  text-align: center;
}

.Browser-button-sync:disabled {
  background: #ccc; /* change background color to a lighter gray when disabled */
  cursor: not-allowed; /* change cursor to indicate that the button is disabled */
}

.stats-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 10px 0;
}

.button-toggle-wrapper {
  display: flex;
  gap: 10px;
  margin-top: 40px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  color: white;
  width: 250px;
  border-radius: 8px;
}

.lock-button, .send-to-ums-button {
  background-color: #1F315C;
  color: white;

  &:disabled, &.disabled {
    background-color: #CCC;
    cursor: not-allowed;
  }
}

.re-sync-button, .download-button {
  background-color: #1F315C;
  color: white;

  &:disabled, &.disabled {
    background-color: #CCC;
    cursor: not-allowed;
  }
}


table {
    width: 45%;
    border-collapse: collapse;
  }
  tbody, tr, td{
    align-items: center;
  }
  th, td {
    padding: 8px 12px;
    border: 1px solid #ccc;
  }
  
  th {
    background-color: #f4f4f4;
  }  
  .confirm-button{
    width: 175px;
    height: 40px;
    border: 1px solid #000;
    background: #000;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 150px;
  }

  .button-section{
    display: flex;
    button{
        width: 125px;
        height: 40px;
        border: 1px solid #000;
        background: #FFF;
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        margin-left: 20px; 
    }
  }
  .account-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 100px;
  }
  .sync-radio{
    display: flex;
    align-items: center;
    text-align: center;
    gap: 15px;
    margin-top: 20px;
  }

  .pagination {
    display: flex;                    // Align items in a row       // Center the pagination
    align-items: center;              // Vertically align items in the center
    margin-top: 50px;                 // Add some top margin
    gap: 10px;                        // Space between elements
}

.MuiPaginationItem-root {             // Target all pagination items
    border-radius: 50%;               // Make buttons circular
    width: 35px;                      // Set width of the circle
    height: 35px;                     // Set height of the circle
    display: flex;                    // Align items inside the button
    justify-content: center;          // Center the number horizontally
    align-items: center;              // Center the number vertically
    font-size: 14px;                  // Font size for the number
    color: #007bff;                   // Text color
    border: 1px solid #007bff;        // Border color
    background-color: #000;          // Background color
    cursor: pointer;                  // Pointer cursor on hover
    transition: all 0.3s ease;
    border-radius: 50%;
    padding: 10px;         // Smooth transition for hover effects
}

.MuiPaginationItem-root.Mui-selected {
    background-color: #000 !important;        // Background color when selected
    color: white; 
    border-radius: 50%;
    padding: 10px;                    
}

.MuiPaginationItem-root:hover {
    background-color: #e0e0e0;        // Background color on hover
    color: #007bff;  
    border-radius: 50%;
    padding: 10px;                   // Text color on hover
}

.MuiPaginationItem-ellipsis {
    border: none;                     // Remove border for ellipsis
    background-color: transparent;    // Transparent background for ellipsis
    cursor: default;                  // Default cursor for ellipsis
}

.MuiPagination-ul {
    gap: 5px;                         // Space between the pagination buttons
    padding: 0;                       // Remove padding around the pagination list
    list-style: none;                 // Remove list style
}

.MuiPaginationItem-previousNext {
    width: auto;                      // Default width for navigation buttons
    height: auto;                     // Default height for navigation buttons
    border-radius: 50%;               // Make navigation buttons circular
    padding: 5px;                     // Padding inside the buttons
    display: flex;                    // Flexbox layout for the button content
    justify-content: center;          // Center content horizontally
    align-items: center;              // Center content vertically
    border: 1px solid #007bff;        // Border color for navigation buttons
    background-color: white;          // Background color for navigation buttons
    transition: all 0.3s ease;        // Smooth transition for hover effects
}

.MuiPaginationItem-previousNext:hover {
    background-color: #e0e0e0;        // Background color on hover for navigation buttons
    color: #007bff;                   // Text color on hover for navigation buttons
}

.MuiPaginationItem-previousNext.Mui-disabled {
    color: #ccc;                      // Text color for disabled navigation buttons
    border: 1px solid #ccc;           // Border color for disabled navigation buttons
    background-color: #f5f5f5;        // Background color for disabled navigation buttons
}

.MuiPaginationItem-previousNext svg {
    width: 20px;                      // Width for the arrow icons
    height: 20px;                     // Height for the arrow icons
}
.scrollable-div {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* Base button styles */
.Browser-button-view{
    background-color: #1F315C; 
    border-radius: 8px; 
    width: 200px;
    height:45px;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    margin-left: 0px;
    cursor: pointer;
    margin-top: 50px;
    text-align: center;
}
.Browser-button-view:disabled {
    background-color: #ccc; /* or any other color you prefer */
    cursor: not-allowed;
  }

.legends-lists{
  display: flex;
}
.legends-lists > li {
  text-decoration: none;
  list-style-type:none;
  margin-right: 20px;
}
.stats-result span {
  display: inline-block;
}