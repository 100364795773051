// components/SelectBox.module.scss
@media only screen and (min-width: 1440px) and (min-height: 1024px) {
  .selectBox {
    margin: 1rem 0;

    .MuiInputBase-root {
      height: 38px !important;
      display: flex;
      align-items: center;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiInputLabel-outlined {
      color: #333;
      font-size: 16px;
    }

    .MuiOutlinedInput-root {
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #1976d2;
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: #1976d2;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: #ccc;
      }

      input {
        padding: 10px;
      }
    }

    .MuiSelect-select {
      display: flex;
      align-items: center;
      padding: 10px;
    }

    .MuiFormHelperText-root {
      color: red;
    }
  }
}