
.table-container {
    position: relative;
    overflow-x: auto; /* enable horizontal scrolling */
    overflow-y: hidden; /* disable vertical scrolling */
    -webkit-overflow-scrolling: touch; /* add this for smooth scrolling on WebKit browsers */
  }
  
  .table-container::-webkit-scrollbar {
    -webkit-appearance: none; /* hide the default scrollbar */
    width: 10px; /* adjust this value to your needs */
    height: 10px; /* adjust this value to your needs */
  }
  
  .table-container::-webkit-scrollbar-thumb {
    border-radius: 10px; /* adjust this value to your needs */
    background-color: rgba(0, 0, 0, 0.5); /* adjust this value to your needs */
    -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.5); /* adjust this value to your needs */
  }

td,
th {
    border: 1px solid #000;
    width: 100px;
    padding: 5px;
}

th {
    background-color: lightgray;
}

table {
    table-layout: fixed;
    width: 100%;
    background-color: lightgray;
}

td:first-child, th:first-child,
td:nth-child(2), th:nth-child(2),
td:nth-child(3), th:nth-child(3) {
    position: sticky;
    left: 0;
    z-index: 0;
    border: 1px solid #000;
}

td:first-child, th:first-child {
    z-index: 0;
    background-color: lightgray;
    width: 30px;
    padding: 0px 20px;
    border: 1px solid #000;
}

td:nth-child(2), th:nth-child(2) {
    left: 50px;
    z-index: 0;
    background-color: lightgray;
    width: 250px;
    border: 1px solid #000;
}

td:nth-child(3), th:nth-child(3) {
    left: 300px;
    z-index: 0;
    background-color: lightgray;
    width: 250px;
    border: 1px solid #000;
}

td:last-child, th:last-child {
    position:sticky;
    right:0;
    z-index:0;
    background-color:lightgray;
    width: 200px;
    padding: 0px 20px;
  }

  td:nth-last-child(2), th:nth-last-child(2) {
    position:sticky;
    right:230px;
    z-index:0;
    background-color:lightgray;
    width: 200px;
    padding: 0px 20px;
  }

  td:nth-last-child(3), th:nth-last-child(3) {
    position:sticky;
    right:400px;
    z-index:0;
    background-color:lightgray;
    width: 200px;
    padding: 0px 20px;
  }
thead tr th {
    position: sticky;
    top: 0;
}

th:first-child, th:nth-child(2), th:nth-child(3), th:last-child {
    z-index: 1;
    background-color: lightgray;
}

