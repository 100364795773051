// src/styles.scss
/* src/Breadcrumbs.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body{
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}
.second-modal,
.third-modal{
  background-color: #FFF;
  padding: 40px 40px;
  height: 250px;
  align-items: center;
  text-align: center;
  border:1px solid gray;
  border-radius: 10px;
}

.download-Template-section{
  margin-bottom:20px;
  margin-top:20px;
  color: #5492DD;
  font-family: Inter;
  font-size: 14px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-weight: 500;

}

.file-section {
  width: 350px;
  height: 250px;
  border: 1px dashed #747887;
  text-align: center;
  margin-top: 30px;
  padding-top: 40px;
}

.upload-button {
  height: 100%;
  width: 100%;
  padding: 10px !important;
  border-radius: 4px !important;
  background: rgb(139, 139, 139) !important;
  color: #fff !important;
  font-family: "Inter", sans-serif !important;
}

.button-close-modal {
  float: right;
  position: relative;
  top: -35px;
  left: 35px;
  width: 50px;
  height: 50px;
}

.Browser-button {
  width: 125px;
  height: 50px;
  border: 1px solid #000;
  background: #000;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  margin-left: 50px;
  cursor: pointer;
}

.close-button {
  width: 125px;
  height: 50px;
  border: 1px solid #000;
  background: #000;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 120px;
}

.Scanning-modal {
  width: auto;
  height: 545px;
  align-items: center;
  font-family: "Inter", sans-serif;

  b {
    font-family: Inter;
    font-size: 13px;
    font-style: italic;
    font-weight: 900;
    line-height: 20px;
    /* 153.846% */
  }

  p {
    color: rgba(30, 30, 30, 0.88);
    font-family: Inter;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    /* 153.846% */
  }
}

.uploading-modal {
  width: auto;
  height: 477px;
  align-items: center;
  font-family: "Inter", sans-serif;

  p {
    color: rgba(30, 30, 30, 0.88);
    font-family: Inter;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    /* 153.846% */
    margin-top: 40px;
  }
}

.file-upload {
  width: auto;
  height: 400px;
  align-items: center;
  font-family: "Inter", sans-serif;

  p {
    color: rgba(30, 30, 30, 0.88);
    font-family: Inter;
    font-size: 13px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    /* 153.846% */
    margin-top: 100px;
  }

  .file-upload-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .uploaded-button {
    width: 125px;
    height: 50px;
    border: 1px solid #000;
    background: #000;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-left: 120px;
    margin-top: 120px;
  }
}

/* src/FileUploadModal.css */
.file-section {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.file-section:hover {
  border-color: #999;
}

.Scanning-modal,
.uploading-modal,
.file-upload {
  text-align: center;
}

.Browser-button,
.uploaded-button,
.close-button {
  margin-top: 20px;
}

.button-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal {
  z-index: 90;
  .sync-modal {
    width: 450px;
    border-radius: 6px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 100px;

    h2 {
      color: #33394E;
      font-family: Inter;
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
    }

    p {
      float: right;
    }
  }
  .second-Modal,
  .thrid-modal {
    width: 575px;
    border-radius: 6px;
    border: 1px solid #DFDFDF;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 80px;
    align-items: center;
    text-align: center;

    h2 {
      color: #33394E;
      font-family: Inter;
      font-size: 16px;
      font-style: italic;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.sync-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap:50px;
  .sync-button,
  .sync-another {
    border-radius: 6px;
    border: 1px solid #1057AC;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background-color: #FFF;
    width: 300px !important;
    height: 40px !important;
    margin-top: 25px;
  }
  .sync-another{
    background-color: #1057AC;
    color: #FFF;
  }
}


.progress-bar {
  width: 100%;
  background: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
}

.progress {
  height: 8px;
  background: #33C481;
  transition: width 2s;
}
/* src/commonComponent/ManualEntryModal.css */
.modal-manual {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-entry {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow-y: auto;
  max-height: 80%;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.edit-icon {
  cursor: pointer;
  margin-left: 10px;
  color: #007bff;
}

.edit-icon:hover {
  color: #0056b3;
}

.modal-close-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-close-button:hover {
  background-color: #0056b3;
}


