/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body{
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}
.logout-button{
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  color: #FFF;
  padding-right: 35px;
}
.home-section {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .container {
    display: flex;
    flex: 1;
  }
  
  .content {
    flex: 1;
    padding: 20px;
    max-height: 80vh;
    overflow: auto; // Enable scrolling

    // Customize scrollbar for WebKit browsers
    &::-webkit-scrollbar {
      width: 8px; // Width of the scrollbar
      height: 8px; // Height for horizontal scrollbar (optional)
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1; // Color of the track (background)
      border-radius: 4px; // Rounded corners for the track
    }
  
    &::-webkit-scrollbar-thumb {
      background: #888; // Color of the thumb (scroll handle)
      border-radius: 4px; // Rounded corners for the thumb
  
      &:hover {
        background: #555; // Color when hovered
      }
    }
  
    &::-webkit-scrollbar-thumb:active {
      background: #333; // Color when active (clicked)
    }
  
    &::-webkit-scrollbar-corner {
      background: #f1f1f1; // Color of the corner between horizontal and vertical scrollbars
    }
  }
  
  /* src/components/Header.css */
.header {
    background-color: #333;
    color: white;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
/* src/components/Sidebar.css */
.sidebar {
    width: 180px;
    background-color: #f4f4f4;
    padding: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    border-right: 1px solid #5492DD;
    background: #5492DD;
  }
  .mapone{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-right: 17px;
  }
  .maptwo,.mapthree,.mapfour{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
  }
  .maptwo {
    margin-right: 36px;
  }
  .mapfour {
    margin-left: 14px;
  }
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  .sidebar ul li a {
    text-decoration: none;
    color: #FFF;
    align-items: center;
    text-align: center;
    font-family: "Inter", sans-serif;
  }
  
  .signin-container {
    align-items: center;
    text-align: center;
    margin: 180px auto;
  }
  
  .signin-container h1 {
    font-family: "Inter", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 80px;
  }
  
  .form-group {
    margin-bottom:40px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 383px;
    height:45px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #1F315C;
    background: #FFF;
    color: #969191;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
  }
  
  button {
    border-radius: 10px;
    background: #CCC;
    width: 380px;
    height: 45px;
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border:none;
  }  
  .forgot-password {
    text-align: center;
    margin-bottom: 40px;
    margin-left: 240px;
  }
  
  .forgot-password a {
    color: #007bff;
    text-decoration: none;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
  }
  